@mixin textElipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cn-template__row {
    td, th {
        font-size: 14px !important;
        &.expanded {
            overflow: auto;
            white-space: normal;
            word-wrap: break-word; /* IE */
            word-break: break-all;
        }
    }
}

.cn-template-expand {
    max-width: 29px;
}
.cn-template-subject {
    width: 400px;
    min-width: 400px;
}
.cn-template-desc {
    max-width: 180px;
    @include textElipsis;

    @media (min-width: 1440px) {
        max-width: 195px;
    }
    @media (min-width: 1520px) {
        max-width: 255px;
    }
    @media (min-width: 1860px) {
        max-width: 415px;
    }
}
.cn-template-case {
    max-width: 130px;

    @media (min-width: 1440px) {
        max-width: 140px;
    }
    @media (min-width: 1660px) {
        max-width: 165px;
        @include textElipsis;
    }
    @media (min-width: 1860px) {
        max-width: 250px;
    }
}
.cn-template-lang {
    max-width: 130px;
    @include textElipsis;
}
.cn-template-user {
    min-width: 220px;
    width: 220px;

    overflow: auto;
    white-space: normal;
    word-wrap: break-word; /* IE */
    word-break: break-all;
}
.cn-template-created {
    max-width: 90px;
    min-width: 80px;

    @media (min-width: 1660px) {
        min-width: 120px;
    }
}
.cn-template-actions {
    max-width: 90px;
    min-width: 80px;

    @media (min-width: 1660px) {
        min-width: 135px;
    }

    span {
        margin-right: 4px;
        margin-top: -2px;
    }
}
.cn-template-expand-collapse {
    cursor: pointer;
}
.cn-template-action-link {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;

    &:first-of-type {
        margin-right: 10%;

        @media (min-width: 1660px) {
            margin-right: 5%;
        }
    }
}
