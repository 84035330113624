@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';
/** 
    overwrite framework variables fro editor
    https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/ui/theme-customization.html
    list of  available variables
    https://github.com/ckeditor/ckeditor5/blob/master/packages/ckeditor5-theme-lark/theme/ckeditor5-ui/globals/_colors.css
*/
.nc-template-form {
    --ck-color-base-background: #{cssvar(primary-bg-color)};
    --ck-color-base-text: #{cssvar(primary-content-color)};
    --ck-color-base-border: #{cssvar(input-border-color)};
    --ck-color-base-active: #{cssvar(input-active-border-color)};

    --ck-border-radius: #{cssvar(input-border-radius)};
    --ck-spacing-unit: #{cssvar(spacing-3)};
    --ck-focus-ring: 1px solid #{cssvar(input-border-color)};
    --ck-inner-shadow: none;

    margin-top: cssvar(spacing-4);

    .ck.ck-editor__editable_inline {
        border-color: cssvar(input-border-color);
        min-height: 250px;
    }

    .cn-template-has-error {
        .ck.ck-editor__editable_inline  {
            border-color: cssvar(danger-color) !important; // needed to overwrite base ckeditor style
        }
    }
}
