.cn--filters {
  margin-top: 15px;

  &__filter {
    margin-bottom: 20px;
  }

  &__select-group {
    display: flex;
  }

  &__type-filter {
    margin-right: 30px;
  }

  &__calendar-container {
    position: relative;
    width: 300px;
  }

  &__calendar {
    position: absolute;
  }

  &__reset {
    margin-top: 27px;
    margin-left: 15px;
  }
}