@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.cn {
    &-header {
        [class*='inner'] {
            max-width: calc(100% - 12px);
        }
    }

    &-container {
        max-width: calc(100% - 12px);
        padding: cssvar(spacing-4) 0;
    }

    &-heading {
        padding-bottom: cssvar(spacing-4);
    }

    &-heading-controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &-popup-content {
        &--left {
            text-align: left;
        }
    }

    &-searchbar {
        width: auto;
        display: flex;
        align-items: center;
        margin-right: 24px;

        &__icon {
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;

            &--show {
                opacity: 0;
            }
        }

        &__form {
            width: 0;
            overflow: hidden;
            transition: width 0.5s ease-in-out;

            &--show {
                width: 100%;
            }
        }
    }

    &-send_template {
        margin-top: 15px;
    }
    &-search-template_input {
        max-width: 350px;
    }
    &-send_template-wrapper {
        max-width: 90%;
        background-color: #FAFAFA;
        padding: 15px;
        border-radius: 10px;
        position: relative;
    }
    &-whatsapp-status {
        &__success {
            color: cssvar(success-color);
        }
        &__fail {
            color: cssvar(danger-color);
        }
    }
    &-whatsapp-placeholders__info {
        margin-bottom: 30px;
    }

    &-select-whatsapp-template {
        max-width: 350px;
        margin-bottom: 10px;
    }
    &-send-template__description {
        margin-bottom: 30px;
    }
    &-send-template__icon {
        position: absolute;
        top: 20px;
        right: 15px;
    }
}

.has-text-right {
    text-align: right;
}

.table-grid-wrapper {
    margin: 0 auto;
}

.not-found {
    text-align: center;
}
