@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';
.cn {
    &-template {
        &__language-select {
            margin-bottom: 20px;
        }
        &__controls {
            margin-bottom: 20px;
        }
        &__channel-icon {
            margin-bottom: 20px;
        }
        &__whatsapp-id {
            margin-bottom: 20px;
        }
        &__placeholder-wrapper {
            display: flex;
            align-items: end;
            margin-bottom: 12px;
        }
        &__remove-placeholder {
            margin-left: 10px;
        }
        &__placeholders-error {
            color: cssvar(danger-color);
            font-size: 14px;
            margin-top: 0;
        }
        &__placeholders {
            margin-bottom: 40px;
        }
    }
    &-template-info {
        position: relative;
        font-size: 24px;
        line-height: 1.1em;
        padding-bottom: 20px;
        &__status {
            position: absolute;
            right: 0;
            top: 0;
            box-shadow: 0 0 1px #888;
            pointer-events: none;
        }
        &__content {
            padding-right: 24px;
        }
    }

    &-template-form__lang {
        font-size: 50px;
        font-weight: 400;
        text-align: center;
    }

    &-lang {
        &-switcher__list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex-shrink: 0;
            justify-content: space-between;
        }
        &-tag__button {
            margin-bottom: 12px;
        }
    }
    &-template-error {
        color: cssvar(danger-color);
    }
}

.content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &__content-counter {
        text-align: right;
    }
}